const dotenv = require('dotenv'); 

function loadConfig() {
  console.log('process.env.NODE_ENV', process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case 'production': return dotenv.config({ path: '.env.prod' });
    case 'test': return dotenv.config({ path: './test/.env.test' });
    case 'development':
    default: return dotenv.config({ path: '.env.dev' });
  }
}

loadConfig();