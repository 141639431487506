import firebase from 'firebase';
import 'dotenv';
import '../../../config/config';

const firebaseConfig = {
  projectId: process.env.PROJECT_ID,
  databaseURL: process.env.DATABASE_FIREBASE_URL,
  storageBucket: process.env.STORAGE_BUCKET,
  locationId: process.env.LOCATION_ID,
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  // measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const functions = firebase.functions();
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();


// export function getProfilePicUrl() {
//   const url = auth.currentUser.photoURL;
//   console.log('url', url);
//   return url;
// }

// // Returns the signed-in user's display name.
// function getUserName() {
//   console.log("firebase auth properties", firebase.auth());
//   return firebase.auth().currentUser.displayName;
// }

// function isUserSignedIn() {
//   console.log("firebase auth is user signed in", firebase.auth().currentUser);
//   return !!firebase.auth().currentUser;
// }


// export function initApp() {
//   firebase.initializeApp(firebaseConfig);
//   console.log('app initialized');
// }

// initApp();


if (window.location.hostname === "localhost") {
  functions.useFunctionsEmulator("http://localhost:5001");
  auth.useEmulator("http://localhost:9099");
}