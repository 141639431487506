import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import '../../assets/css/style.min.css';
import '../../assets/images/icons/logo.svg';
import Logo from '../../assets/images/icons/logo_transparent.png';
import UserIcon from '../../assets/images/icons/user.svg';
import Caret from '../../assets/images/icons/arrow_down.svg';
import HomeIcon from '../../assets/images/icons/home.svg';
import { RootStoreContext } from '../../stores/rootStore.js';
import { auth } from '../../utils/firebaseInitApp.js';

const HomeButton = () => {
  let history = useHistory();
  const handleClick = () => {
    history.push("/listofcaravans");
  }
  return (
    <button type="button" onClick={handleClick}>
      Go Home
    </button>
  );
}

const getCaravanStore = () => {
	const rootStore = React.useContext(RootStoreContext);
	const { caravanstore } = rootStore;
	return caravanstore;
};


const getUserStore = () => {
	const rootStore = useContext(RootStoreContext);
	const { userstore } = rootStore;
	return userstore;
};

const signOutOfCaravan = action((UserStore, CaravanStore, e) => {
  console.log('signout function running');
  UserStore.loadingUser = true;
  UserStore.email = null;
  UserStore.token = null;
  UserStore.profilePhoto=null;
  UserStore. userObj={};
  CaravanStore.caravans=[];
  CaravanStore.loading=true;
  CaravanStore.isDirty=true;
  CaravanStore.loadEditForm=false;
  e.preventDefault();
  auth.signOut();
  //.then(window.location = 'login');
});
  
 
  
 const NavigationBar = observer(() => {
	const UserStore = getUserStore();
	const CaravanStore = getCaravanStore();
	const getProfilePhoto = UserStore.profilePhoto;
	const userEmail = UserStore.email;

	if(userEmail) {
		return (
			<header className="header">
			<Link to="/listofcaravans">
				<picture><source srcSet={Logo} type="image/png"/><img src={Logo} className="header__logo caravan-logo" width="184" alt="Caravan logo" /></picture>
			</Link>
			<nav className="header__nav">
				<Link to="/listofcaravans" className="header__nav-item">
					<picture><source srcSet={HomeIcon} type="image/webp"/><img src={HomeIcon} width="26" alt="Home icon" /></picture>
				</Link>
				<div className="header-dropdown">
					<div className="header-dropdown__icon header__nav-item">
						<picture><source srcSet={getProfilePhoto} type="image/webp" /><img src={getProfilePhoto} width="32" alt="Avatar icon"/></picture>
						<picture><source srcSet={Caret} type="image/webp"/><img src={Caret} className="header-dropdown__arrow" alt="arrow down" /></picture>
					</div>
					<div className="header-dropdown__inner">
						<div className="header-dropdown__content">
							<a href="#" className="header-dropdown__link" onClick={(e) => signOutOfCaravan(UserStore, CaravanStore, e)}>Sign out</a>
						</div>
					</div>
				</div>
				{/* <a href="#" className="header__nav-item">
					<picture><source srcSet={getProfilePhoto} type="image/webp" /><img src={getProfilePhoto} width="32" alt="Avatar icon"/></picture>
				</a> */}
			</nav>
			</header>
	)
	} else {
		return (
		  <React.Fragment>
			<header className="header">
			  <img src={Logo} className="header__logo" width="184" alt="Caravan logo"/>
			</header>
		  </React.Fragment>
		);
	  }
 })
 

// TODO: clarify if webp and png versions are both needed
// TODO: what do we do with <head>?
// class NavigationBar extends Component {
//     render() {
//     return (
// 	<header className="header">
// 	<a href="/listofcaravans">
// 		<picture><source srcSet={LogoWP} type="image/webp"/><img src={Logo} className="header__logo" width="184" alt="Caravan logo" /></picture>
// 	</a>
// 	<nav className="header__nav">
// 		<div className="header-dropdown">
// 			<div className="header-dropdown__icon header__nav-item">
// 				<picture><source srcSet={UserIcon} type="image/webp"/><img src={UserIcon} width="23" alt="User icon" /></picture>
// 				<picture><source srcSet={Caret} type="image/webp"/><img src={Caret} className="header-dropdown__arrow" alt="arrow down" /></picture>
// 			</div>
// 			<div className="header-dropdown__inner">
// 				<div className="header-dropdown__content">
// 					<a href="#" className="header-dropdown__link" onClick={signOut}>Sign out</a>
// 				</div>
// 			</div>
// 		</div>
// 		<a href="/listofcaravans" className="header__nav-item">
// 			<picture><source srcSet={HomeIcon} type="image/webp"/><img src={HomeIcon} width="26" alt="Home icon" /></picture>
// 		</a>
// 	</nav>
//     </header>
//     )
//     }
// }

// {/* 
// <head>
// 		<meta charset="UTF-8" />
// 		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
// 		<title>Caravan - Homepage</title>
// 		<link rel="stylesheet" href={Styles} />
//     </head> */}
    
export default NavigationBar;

