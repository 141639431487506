/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { makeAutoObservable, action, observable } from 'mobx';
import apiService from '../api/apiService.js';


// test: https://startupworkflow.ngrok.io/fetchcaravansetups
// dev: https://letscaravan-x5axvbp4ja-uc.a.run.app

export default class CaravanStore {
	caravans=[];

	loading=true;

	isDirty=true;

	loadEditForm=false;

	loadCaravans = async(token) => {
		console.log('isDirty ===', this.isDirty);
		if (this.caravans.length === 0 || this.isDirty ) {
			console.log('load caravans is running');
			const response = await apiService.getData('/fetchcaravansetups', token);
			const { data } = response;
			this.caravans = data;
			this.loading = true;
			this.isDirty = false;
		};
	}

	constructor() {
	  makeAutoObservable(this, {
		  loading: observable,
		  isDirty: observable,
		  caravans: observable,
		  loadCaravans: action,
		  loadEditForm: observable,
	  });
	}
}
