require('../../../config/config');
const axios =require('axios');
// perform CRUD
// import axios
// create three functions
// -- one for getting all
// -- one for get using id
// creating 
// updating
// deleting

// - take in parameters, pass url as a parameter, pass in token
// do response and error handling here /async/await
//Loader and success/fail modal
// handle communication between front end and backend
//dummy components only take in data. they don't do any business logic


const createInstance = (token) => {
    return axios.create({
    baseURL: process.env.BASE_URL,
    headers:  { 
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}` 
    },
    });
}

const createInstanceNoToken = () => {
    return axios.create();
}

const createInstanceOnlyUrl = () => {
    return axios.create({
        baseURL: process.env.BASE_URL,
    });
}


module.exports = {
    getData: async(url, token) => {
    let instance = createInstance(token);
    const data = await instance({
        'method':'GET',
        'url': url, // passed as string like '/listofcaravans'
    });
    return data;
    },

    postData: async(url, formData, token) => {
        let instance = createInstance(token);
        const response = await instance({
            'method': 'POST',
            'url': url,
            'data': formData
        });
        return response.data; // should be  'OK'
    },

    getEmailValidation: async(email) => {
        let instance = createInstanceNoToken();
        // const response = await instance({
        //     'method':'GET',
        //     'url': `https://apilayer.net/api/check?access_key=${process.env.MAILLAYER_API_KEY}&email=${email}&&catch_all=1`
        // });

        const response = await instance({
            'method':'GET',
            'url': `https://api.debounce.io/v1/?api=${process.env.DEBOUNCE_API_KEY}&email=${email}`
        });
        return response.data;
    },
    postDataWithoutToken: async(url, formData) => {
        let instance = createInstanceOnlyUrl();
        const response = await instance({
            'method': 'POST',
            'url': url,
            'data': formData
        });
        return response.data; // should be  'OK'
    },
    
    getDataWithoutToken: async(url) => {
        let instance = createInstanceOnlyUrl();
        const data = await instance({
            'method':'GET',
            'url': url, // passed as string like '/listofcaravans'
        });
        return data;
        },
}