import { action, makeAutoObservable, observable } from 'mobx';
import { auth } from '../utils/firebaseInitApp.js';

export default class UserStore {
  email=null;

  loadingUser=true;

  profilePhoto=null;

  userObj={};
  
  token=null;

  expiryTime=null;

  displayName=null;


  retrieveAuthToken = async() => {
    console.log('retrieveAuthToken is running');
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    this.expiryTime = new Date(idTokenResult.expirationTime).valueOf();
    this.token=idTokenResult.token;
	}

  authStateObserver = (user) => {
    console.log('auth state observer is running');
    if (user) {
      this.email = user.email;
      this.profilePhoto = user.photoURL;
      this.userObj = user;
      this.displayName = user.displayName;
    }
    // this.email = user ? user.email : null;
    this.loadingUser = false;
    this.retrieveAuthToken();
  }

  InitFirebaseAuth = () => {
    // Listen to auth state changes.
    console.log('init firebase is running');
    auth.onAuthStateChanged(this.authStateObserver);
    console.log('firebase.auth().onAuthStateChanged is running');
  }
  
  constructor() {
    makeAutoObservable(this, {
      email: observable,
      loadingUser: observable,
      profilePhoto: observable,
      userObj: observable,
      expiryTime: observable,
      displayName: observable,
      authStateObserver: action,
      InitFirebaseAuth: action,
      token: observable,
      retrieveAuthToken: action
    });
  }
}