import React, { useMemo, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { render } from "react-dom";
import { observer } from 'mobx-react-lite';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import './assets/css/style.min.css';
import './assets/images/icons/logo_transparent.png';
import { RootStoreContext, RootStore } from './stores/rootStore.js';
import './utils/firebaseInitApp.js'; 
import NavBar from './components/shared/NavBar.jsx';

const ListOfCaravans = React.lazy(() => import('./main_pages/listofcaravans.jsx'));

const LoginPage = React.lazy(() => import('./main_pages/login.jsx'));

const CreateCaravanForm = React.lazy(() => import('./main_pages/createcaravan.jsx'));

const ViewCaravanResults = React.lazy(() => import('./main_pages/viewcaravanresults.jsx'));

const EditCaravan = React.lazy(() => import('./main_pages/editcaravan.jsx'));

const ResponseForm = React.lazy(() => import('./main_pages/responseform.jsx'));


export const rootStore = new RootStore();


const RenderLoader = () => {
  return (
  <React.Fragment>
      <div className="spinner">
      <Loader type="Circles" color="#44bbbd" height={80} width={80} />
      </div>
  </React.Fragment>

)}


const RenderRoutes = observer(({ Component }) => { // written as observer because we have observables here and the rendered component needs to change everytime there is a change in observable
  const userstore = rootStore.userstore;
  const { email, loadingUser } = userstore;
  if (email) {
    return (
        <RootStoreContext.Provider value={rootStore} >
        <Component />
        </RootStoreContext.Provider>
    );
  }
  if (loadingUser === true) {
    return (
    <RootStoreContext.Provider value={rootStore} >
    <RenderLoader />
    </RootStoreContext.Provider >

    )
  }
  return (
    <RootStoreContext.Provider value={rootStore} >
      <Redirect to="/login" />
      </RootStoreContext.Provider >


  );
});

const RenderLoginPage = observer(({}) => {
  const userstore = rootStore.userstore;

  const { email, loadingUser } = userstore;

  if (email) {
    return (
      <RootStoreContext.Provider value={rootStore} >
      <RenderRoutes Component={ListOfCaravans} />
      </RootStoreContext.Provider >
    );
  }

  return (
    <RootStoreContext.Provider value={rootStore} >
      <LoginPage />
      </RootStoreContext.Provider >

  );
});


const App = observer(() =>{
  const userstore = rootStore.userstore;
  useEffect(() => {
    if (userstore) {
       userstore.InitFirebaseAuth(); 
    }
  }, []);

  return(
  <Router>
    <Switch>
      <Route path="/listofcaravans">
      <React.Suspense fallback={RenderLoader()}>
        <RenderRoutes Component={ListOfCaravans} />
      </React.Suspense>
      </Route>
      <Route path="/login">
      <React.Suspense fallback={RenderLoader()}>
        <RenderLoginPage />
      </React.Suspense>
      </Route>
      <Route path="/createcaravan">
      <React.Suspense fallback={RenderLoader()}>
        <RenderRoutes Component={CreateCaravanForm} />
      </React.Suspense>
      </Route>
      <Route path="/caravanqns/*">
      <React.Suspense fallback={RenderLoader()}>
        <RenderRoutes Component={ViewCaravanResults} />
      </React.Suspense>
      </Route>
      <Route path="/editcaravan/*">
      <React.Suspense fallback={RenderLoader()}>
        <RenderRoutes Component={EditCaravan} />
      </React.Suspense>
      </Route>
      <Route path="/calendarresponse/*">
      <React.Suspense fallback={RenderLoader()}>
        <ResponseForm />
      </React.Suspense>
      </Route>
    </Switch>
  </Router>
  )
})

render(<App />, document.getElementById('root'));