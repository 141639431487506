import React from 'react';
import CaravanStore from './caravanStore.js';
import UserStore from './userStore.js';

export class RootStore {
    caravanstore = new CaravanStore(this);

    userstore = new UserStore(this);
}

export const RootStoreContext = React.createContext({});
